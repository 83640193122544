.smallRowHeight {
    line-height: 1.00 !important;
    height: 12px; /* Adjust the height as needed */
    padding-right: 4px !important;
}

.tableContainer {
    height: 100%;
    overflow: auto;
    margin-top: 20px;
}

.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; /* Ensure header background color matches the table */
}