.candlestick-container {
	display: flex;
	flex-direction: column;
}

.candlestick-form {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.candlestick-form-input {
	display: flex;
	flex: 1 0 0;
}

.candlestick-form-date {
	height: 50px;
	position: relative;
	z-index: 2;
}
