.container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.smallFont {
    font-size: 20px; /* Adjust the size as needed */
}

.smallRowHeight {
    line-height: 0.9 !important;
    height: 12px; /* Adjust the height as needed */
    padding-right: 4px !important;
}
