.backtestDetail-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white; /* or any other background color */
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.active {
    background-color: #007bff; /* Change to the color you prefer */
    color: black;
}

.allCharts-container{
    display: flex;
    flex-direction:column;
    width: 100%;
}

.allCharts-chart-name{
    display: flex;
    justify-content: center;
    align-self: center;
    
    padding-bottom: 10px;
    padding-top: 30px;
}

.allCharts-candlestick-container{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.allCharts-barduration-autocomplete{
    width: 30%;
}


.backtestDetail-container {
    position: relative;
}

.backtestDetail-content {
    text-align:center;
}

.backtestDetail-barduration-text-field{
    height: 20px ;
}