.chart-container {
    height: 550px;
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.chart-container-add {
    width: 90%;
    margin: 0 auto;
    position: relative;
}