.chart-wrapper {
    position: relative;
    margin-top:30px;
}

.trade-number {
    position: absolute;
    top: 50%; /* Adjust the position as needed */
    left: 10px; /* Adjust the position as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: background for better visibility */
    color: white; /* Text color */
    padding: 5px; /* Optional: padding for better appearance */
    border-radius: 3px; /* Optional: rounded corners */
    z-index: 10; /* Ensure it is above the chart */
}

.trade-info {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.chart-container {
    height: 550px;
    width: 95%;
    margin: 0 auto;
    position: relative;
}